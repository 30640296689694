const images = {
  logo: require('./logo.png'),
  homeBanner: require('./home-banner.jpg'),
  lotus: require('./lotus.png'),
  interview: require('./interview.jpg'),
  extraBanner: require('./extra-banner.png'),
  creativeDesigns: require('./creative-designs.png'),
  professionalStaff: require('./professional-staff.png'),
  topProducts: require('./top-products.png'),
  fairPrices: require('./fair-prices.png'),
  serviceDemo: require('./service-demo.png'),
  nailExtension: require('./nail-extension.jpg'),
  manicure: require('./manicure.jpg'),
  sns: require('./sns.jpg'),
  nailService: require('./nail-service.png'),
  nailStyles: require('./nail-styles.jpg'),
  frontStore: require('./front-store.jpg'),
  about1: require('./about-1.jpg'),
  about2: require('./about-2.jpg'),
  aboutFooter: require('./about-footer.png'),
  cost: require('./cost.jpg'),
  galleryFooter: require('./gallery/gallery-footer.png'),
};

export default images;
