import AboutUs from 'src/pages/aboutUs';
import Contact from 'src/pages/contact';
import Gallery from 'src/pages/gallery';
import Home from 'src/pages/home';
import Policy from 'src/pages/policy';
import Treatment from 'src/pages/treatments';

const publicRoutes = [
  { path: '/', component: Home },
  { path: '/treatments', component: Treatment },
  { path: '/about-us', component: AboutUs },
  { path: '/gallery', component: Gallery },
  { path: '/contact-us', component: Contact },
  { path: '/policy', component: Policy },
];

export default publicRoutes;
