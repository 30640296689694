import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const FacebookPage = () => {
  return (
    <div className={cx('container')}>
      <div
        data-href='https://www.facebook.com/HVT-NAilS-spa-324216048206957/'
        data-small-header='false'
        data-adapt-container-width='true'
        data-hide-cover='false'
        data-show-facepile='false'
        fb-xfbml-state='rendered'
        fb-iframe-plugin-query='adapt_container_width=true&amp;app_id=&amp;container_width=555&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FHVT-NAilS-spa-324216048206957%2F&amp;locale=en_GB&amp;sdk=joey&amp;show_facepile=false&amp;small_header=false'
      >
        <span style={{ verticalAlign: 'bottom', width: '340px', height: '130px' }}>
          <iframe
            name='f51516d6743a3b99c'
            width='1000px'
            height='1000px'
            data-testid='fb:page Facebook Social Plugin'
            title='fb:page Facebook Social Plugin'
            frameborder='0'
            allowtransparency='true'
            allowfullscreen='true'
            scrolling='no'
            allow='encrypted-media'
            src='https://www.facebook.com/v2.8/plugins/page.php?adapt_container_width=true&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df5d10d69cf5b856d3%26domain%3Dwww.hvtnailsspa.co.uk%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fwww.hvtnailsspa.co.uk%252Ff5e90ba46ff1340ff%26relation%3Dparent.parent&amp;container_width=555&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FHVT-NAilS-spa-324216048206957%2F&amp;locale=en_GB&amp;sdk=joey&amp;show_facepile=false&amp;small_header=false'
            style={{ border: 'none', visibility: 'visible', width: '340px', height: '130px' }}
          ></iframe>
        </span>
      </div>
    </div>
  );
};
