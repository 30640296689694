import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import Header from 'src/layouts/components/header';
import Footer from 'src/layouts/components/footer';

const cx = classNames.bind(styles);

const MainLayout = ({ children }) => {
  return (
    <div className={cx('wrapper')}>
      <div className={cx('container')}>
        <Header />
        <div className={cx('content')}>{children}</div>
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
