import React, { useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import images from 'src/assets/images';

const cx = classNames.bind(styles);

const Contact = () => {
  return (
    <div className={cx('wrap')}>
      <div className={cx('container')}>
        <h3 className={cx('primary-title')}>Contact Us</h3>
        <span className={cx('slim-title')}>Get in Touch</span>
        <p>If you have questions, comments, or other feedback related to our services, please contact us to discuss.</p>
        <div className={cx('contact-wrap')}>
          <div className={cx('contact-container')}>
            <div className={cx('contact-follow')}>
              <h4 className={cx('underline-title')}>Contact Details</h4>

              <p>North Point Shopping Centre, HU7 4EE</p>

              <div>
                Tel:{' '}
                <a href='tel:07445 048 996' className={cx('margin-bottom-20')}>
                  07445 048 996
                </a>
              </div>

              <div className={cx('margin-top-20', 'margin-bottom-20')}>
                <h4 className={cx('underline-title')}>Opening Hours</h4>
                <p>Mon - Sat: 9am - 6pm</p>
                <p>Sunday: Closed</p>
              </div>
            </div>
          </div>
          <div className={cx('map-wrap')}>
            <p className={cx('map-title')}>We're in the North Point Shopping Centre opposite Boyes</p>

            <div className={cx('map-container')}>
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9428.758970454837!2d-0.3212879!3d53.7861104!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe54a91cb92a5beee!2sHVT%20Nails%20%26%20Spa!5e0!3m2!1sen!2suk!4v1575902281638!5m2!1sen!2suk'
                width={600}
                height={450}
                frameborder={0}
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
