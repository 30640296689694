import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const Policy = () => {
  return (
    <section className={cx('page-section')}>
      <div className={cx('container')}>
        <div className={cx('policy-header')}>
          <h3 className={cx('primary-title')}>Salon Policy</h3>
          <span className={cx('slim-title')}>Protect Your Right</span>
        </div>

        <div className={cx('align-left')}>
          <h5 className={cx('text-highlight')}>
            Our policy is to protect your right and guarantee your satisfaction with the service(s) that we provided It
            is not effected to your rights under Consumer Right Act 2015
          </h5>

          <p>To allow us to provide a quality service as efficiently as possible, you need to :</p>

          <p>1. Ask for the service which you would like to have.</p>
          <p>
            2. Inform our staff if you are allergic to any chemicals or substances before the receiving any service.
            Also, if you have DIABETES.
          </p>
          <p>
            3. Whilst receiving any service please provide our staff with your feedback and or comments on their
            services so our staff can satisfy your requirements straight away.
          </p>
          <p>
            4. Inform the staff /salon manager immediately if you have any concern relating to the salon, the staff, or
            any service.
          </p>
          <p>
            5. Inform the staff /salon manager immediately if you have any concern that you have any infection or any
            other abnormal reaction or condition with respect to your nails.
          </p>
          <p>
            6. Children under 7 years old should be accompanied by an adult at all times. We will not take any
            responsibility if any accident happens to your child/children during your treatment at our salon.
          </p>
          <p>
            7. We hold no responsibility for the loss of your personal belonging while you are having the treatment at
            our salon.
          </p>
          <p>
            7. Please do not touch any of the salon equipment. If handled wrongly, it can be very dangerous e.g. nail
            drills
          </p>

          <h3 className={cx('margin-top-20')}>GUARANTEE</h3>
          <h5 className={cx('text-highlight')}>
            Please remember, nails are jewels, not tools. Be kind to your nails and they will look beautiful for weeks
            after your service
          </h5>
          <p>1. Traditional polish services are not guaranteed.</p>
          <p>
            2. Enhancements and gel polish manicures are guaranteed for 1 week after your appointment. If you lose an
            enhancement or notice chips or lifting within 1 week, please call us to schedule a free repair.
          </p>
          <p>3. You must arrange an appointment to have your nails fixed within 5 days after your initial service.</p>
          <p>4. Repairs after 3 days or for breakages, tears, and corner breaks will be charged.</p>
          <p>5. We will not be responsible for any breaking or infected nails after you leave the salon .</p>

          <h3 className={cx('margin-top-20')}>REFUND</h3>
          <h5 className={cx('text-highlight')}>
            No cash refunds will be given after you have left the salon. As soon as you have walked out the salon door
            that means you have accepted and are happy with the service provided to your nails.
          </h5>
          <p>
            1. If you are not happy with your nails, please make us aware of it before you pay. We will adjust them to
            your satisfaction or remove any enhancements or coatings that have been applied.
          </p>
          <p>2. No refund will be given because you have simply changed your mind.</p>
        </div>
      </div>
    </section>
  );
};

export default Policy;
