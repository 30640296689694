import React, { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import images from 'src/assets/images';
import gallery from 'src/assets/images/gallery';

const cx = classNames.bind(styles);

const GalleryPage = () => {
  const photos = [
    {
      original: gallery[1],
      thumbnail: gallery[1],
    },
    {
      original: gallery[2],
      thumbnail: gallery[2],
    },
    {
      original: gallery[3],
      thumbnail: gallery[3],
    },
    {
      original: gallery[4],
      thumbnail: gallery[4],
    },
    {
      original: gallery[5],
      thumbnail: gallery[5],
    },
    {
      original: gallery[6],
      thumbnail: gallery[6],
    },
    {
      original: gallery[7],
      thumbnail: gallery[7],
    },
    {
      original: gallery[8],
      thumbnail: gallery[8],
    },
    {
      original: gallery[9],
      thumbnail: gallery[9],
    },
    {
      original: gallery[10],
      thumbnail: gallery[10],
    },
    {
      original: gallery[11],
      thumbnail: gallery[11],
    },
    {
      original: gallery[12],
      thumbnail: gallery[12],
    },
    {
      original: gallery[13],
      thumbnail: gallery[13],
    },
    {
      original: gallery[14],
      thumbnail: gallery[14],
    },
    {
      original: gallery[15],
      thumbnail: gallery[15],
    },
    {
      original: gallery[16],
      thumbnail: gallery[16],
    },
  ];

  return (
    <div className={cx('wrap')}>
      <div className={cx('container')}>
        <h3 className={cx('primary-title')}>Gallery</h3>
        <span className={cx('slim-title')}>Designs By Us!</span>
        <p>
          Please check out our{' '}
          <a href='https://www.facebook.com/HVT-NAilS-spa-324216048206957/' target='_blank' className={cx('underline')}>
            Facebook page
          </a>{' '}
          for more inspirational designs...
        </p>
        <div className={cx('gallery-images')}>
          <ImageGallery items={photos} />
        </div>
      </div>
      <div className={cx('sep-lane')}>
        <span></span>
      </div>
      <div className={cx('sep-lane-box')} style={{ paddingTop: '70px', paddingBottom: '70px' }}>
        <span className={cx('slim-title')}>Creative Nail Arts</span>
        <p style={{ padding: '15px 0' }}>We have many designs and styles to choose from</p>
        <img src={images.galleryFooter} />
      </div>
    </div>
  );
};

export default GalleryPage;
