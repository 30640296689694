import React, { useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { FaPoundSign } from 'react-icons/fa';

import images from 'src/assets/images';

const cx = classNames.bind(styles);

const Treatment = () => {
  return (
    <div className={cx('wrap')}>
      <div className={cx('container')}>
        <div className={cx('padding-horizontal-15')}>
          <h3 className={cx('primary-title')}>Our Treatments</h3>
          <span className={cx('slim-title')}>Salon Services</span>
          {/* <div className={cx('info-boxes')}>
            <div className={cx('info-box-icon')}>
              <FaPoundSign />
            </div>
            <div className={cx('info-boxes-content')}>
              <h3 className={cx('info-title')}>Special Offer</h3>
              <span className={cx('info-content')}>20% Off All Services</span>
            </div>
          </div> */}
          <div className={cx('pricing-wrap')}>
            <div className={cx('pricing-container')}>
              <article className={cx('pricing-table')}>
                <div className={cx('pricing-media')}>
                  <img src={images.nailService} />
                </div>

                <img src={images.cost} className={cx('cost-services')} />
              </article>
            </div>
          </div>
        </div>
        <div className={cx('sep-lane')}>
          <span></span>
        </div>
        <div className={cx('sep-lane-box')}>
          <span className={cx('slim-title')}>Salon Services</span>
          <p>We only use top brands to maintain the look and quality of your nails</p>
          <div className={cx('footer-img')}>
            <div className={cx('col-md-2')}></div>
            <img src={images.nailStyles} class={cx('img-circle')} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Treatment;
