import React, { useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

import images from 'src/assets/images';
import { FacebookPage } from 'src/components/facebookPage';

const cx = classNames.bind(styles);

const Home = () => {
  return (
    <div className={cx('wrap')}>
      <div className={cx('wrap-banner')}>
        <img src={images.homeBanner} className={cx('banner')} />
      </div>
      <div className={cx('summary')}>
        <div className={cx('container')}>
          {/* <div className={cx('info-boxes')}>
            <div className={cx('info-box-icon')}>
              <FaPoundSign />
            </div>
            <div className={cx('info-boxes-content')}>
              <h3 className={cx('info-title')}>Special Offer</h3>
              <span className={cx('info-content')}>20% Off All Services</span>
            </div>
          </div> */}

          <div className={cx('wrap-interview')}>
            <div className={cx('left-interview')}>
              <h3 className={cx('primary-title')}>Welcome!</h3>
              <span className={cx('slim-title')}>Creative Nail Studio</span>

              <div className={cx('interview-content')}>
                <div className={cx('paragraph-first')}>
                  <div>
                    <span className={cx('circle-letter')}>H</span>
                  </div>
                  <p className={cx('inline')}>
                    HVT Nails &amp; Spa is a professional, modern nail salon located in the North Point Shopping Centre
                    on Goodhart Rd in the centre of Hull.
                  </p>
                </div>

                <p>
                  We offer amazing bespoke and designer quality nail art from simple elegance to the most complex
                  designs, every customer will receive the same high quality service from your expert nail designers and
                  technicians. We pride ourselves on our work and our fresh approach to clients.
                </p>

                <FacebookPage />
              </div>
            </div>
            <div className={cx('right-interview')}>
              <img className={cx('interview-image')} src={images.interview} />
            </div>
          </div>
        </div>
        <img className={cx('extra-banner')} src={images.extraBanner} />
      </div>

      <div className={cx('sep-lane')}>
        <span></span>
      </div>
      <div className={cx('sep-lane-box')}>
        <div className={cx('container')}>
          <span className={cx('slim-title')}>Why Choose Us?</span>

          <div className={cx('wrap-questions')}>
            <div className={cx('question-item')}>
              <div className={cx('question-symbol')}>
                <img src={images.creativeDesigns} />
              </div>
              <h3 className={cx('underline-title')}>Creative Designs</h3>
              <div className={cx('card-content')}>
                <p>We have many trendy and beautiful designs to suit your taste </p>
              </div>
            </div>
            <div className={cx('question-item')}>
              <div className={cx('question-symbol')}>
                <img src={images.professionalStaff} />
              </div>
              <h3 className={cx('underline-title')}>Professional Staff</h3>
              <div className={cx('card-content')}>
                <p>Our treatments are offered by certified and experienced beauty technicians </p>
              </div>
            </div>
            <div className={cx('question-item')}>
              <div className={cx('question-symbol')}>
                <img src={images.topProducts} />
              </div>
              <h3 className={cx('underline-title')}>Top Products</h3>
              <div className={cx('card-content')}>
                <p>We only use top quality leading industry products for long lasting nails</p>
              </div>
            </div>
            <div className={cx('question-item')}>
              <div className={cx('question-symbol')}>
                <img src={images.fairPrices} />
              </div>
              <h3 className={cx('underline-title')}>Fair Prices</h3>
              <div className={cx('card-content')}>
                <p>Our treatment prices are fair &amp; competitive yet uncompromising on quality </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cx('wrap-services')}>
        <div className={cx('container')}>
          <div className={cx('header-service')}>
            <h3 className={cx('primary-title')}>Our Services</h3>
            <span className={cx('slim-title')}>Treatments for You</span>
            <p>Here are some highlighted services in our salon. See our treatment page for full list of services.</p>
          </div>

          <div className={cx('services')}>
            <div className={cx('service-item')}>
              <div className={cx('item-image')}>
                <img src={images.nailExtension} />
              </div>
              <h3 className={cx('underline-title')}>Nail Extension / Acrylic</h3>
              <div className={cx('card-content')}>
                <p>
                  Our trained technicians offer a variety of treatments including trendy Acrylic, Gel Polish, Pink &
                  White, American Gel, Ombre, French Polish, Nail Art Design to make your nails look amazing!
                </p>
              </div>
            </div>
            <div className={cx('service-item')}>
              <div className={cx('item-image')}>
                <img src={images.nailExtension} />
              </div>
              <h3 className={cx('underline-title')}>Manicure & Pedicure</h3>
              <div className={cx('card-content')}>
                <p>
                  A wonderful experience includes buffing, rubbing, kneading and polishing using a variety of natural
                  products and techniques to improve the appearance of the feet and their nails.
                </p>
              </div>
            </div>
            <div className={cx('service-item')}>
              <div className={cx('item-image')}>
                <img src={images.nailExtension} />
              </div>
              <h3 className={cx('underline-title')}>SNS Dipping Powder</h3>
              <div className={cx('card-content')}>
                <p>
                  Signature Nail Systems (SNS) dipping powder has no odor, no liquid, no primer and no UV light. SNS is
                  for healthy nails and are much thinner than gel or acrylic.
                </p>
              </div>
            </div>
          </div>

          <div className={cx('footer-service')}>
            <a href='/treatments' className={cx('button')}>
              See Our Treatments
            </a>
          </div>
        </div>
      </div>

      <div className={cx('sep-lane')}>
        <span></span>
      </div>
      <div className={cx('sep-lane-box')} style={{ paddingTop: '70px', paddingBottom: '70px' }}>
        <span className={cx('slim-title')}>Fun & Relaxing Salon</span>
        <p style={{ padding: '15px 0' }}>Our salon is relax and a great place to unwind and be pampered!</p>
        <img src={images.serviceDemo} />
      </div>
    </div>
  );
};

export default Home;
