import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import images from 'src/assets/images';

const cx = classNames.bind(styles);

const Footer = () => {
  return (
    <footer className={cx('wrapper')}>
      <div className={cx('footer-rect')}>
        <img src={images.lotus} className={cx('center-icon')} />
      </div>
      <div className={cx('footer-section')}>
        <div className={cx('container')}>
          <div className={cx('footer-item')}>
            <img src={images.logo} />
          </div>
          <div className={cx('footer-item')}>
            <h3 className={cx('footer-title')}>Contacts</h3>
            <p>North Point Shopping Centre, HU7 4EE</p>
            <div>
              Tel:{' '}
              <a href='tel:07445 048996' className={cx('contact-methods')}>
                07445 048 996
              </a>
            </div>
            <h3 className={cx('footer-title-small')}>Working Hours</h3>
            <p>Mon - Sat: 9am - 6pm</p>
            <p>Sunday: Closed</p>
          </div>
          <div className={cx('footer-links')}>
            <h3 className={cx('footer-title')}>Quick Links</h3>
            <ul className={cx('list-unstyled')}>
              <li>
                <a href='/treatments' className={cx('page-link')}>
                  Treatments
                </a>
              </li>
              <li>
                <a href='/gallery' className={cx('page-link')}>
                  Gallery
                </a>
              </li>
              <li>
                <a href='/about-us' className={cx('page-link')}>
                  About Us
                </a>
              </li>
              <li>
                <a href='/contact-us' className={cx('page-link')}>
                  Contacts
                </a>
              </li>
              <li>
                <a href='/policy' className={cx('page-link')}>
                  Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
