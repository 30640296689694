import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './styles.module.scss';
import './header.css';
import classNames from 'classnames/bind';
import { CCollapse, CContainer, CNavLink, CNavbar, CNavbarBrand, CNavbarNav, CNavbarToggler } from '@coreui/react';

import images from 'src/assets/images';

const cx = classNames.bind(styles);

const Header = () => {
  const [visible, setVisible] = useState(false);
  const location = useLocation();

  const path = location.pathname;

  return (
    <header className={cx('wrap')}>
      <CNavbar expand='lg' colorScheme='light'>
        <CContainer fluid>
          <div className={cx('header-mob')}>
            <div className={cx('logo-mob')}>
              <a href='/'>
                <img src={images.logo} />
              </a>
            </div>
            <CNavbarToggler
              aria-label='Toggle navigation'
              aria-expanded={visible}
              onClick={() => setVisible(!visible)}
            />
          </div>
          <CCollapse className='navbar-collapse' style={{ width: '100%' }} visible={visible}>
            <CNavbarNav component='nav' style={{ margin: 'auto' }}>
              <CNavLink href='/' className={cx('title', `${path === '/' ? 'active' : ''}`)}>
                Home
              </CNavLink>
              <div className={cx('sep')}>/</div>
              <CNavLink href='/treatments' className={cx('title', `${path === '/treatments' ? 'active' : ''}`)}>
                Treatments
              </CNavLink>
              <div className={cx('sep')}>/</div>
              <CNavLink href='/about-us' className={cx('title', `${path === '/about-us' ? 'active' : ''}`)}>
                About Us
              </CNavLink>
              <div className={cx('logo')}>
                <CNavLink href='/'>
                  <img src={images.logo} />
                </CNavLink>
              </div>
              <CNavLink href='/gallery' className={cx('title', `${path === '/gallery' ? 'active' : ''}`)}>
                Gallery
              </CNavLink>
              <div className={cx('sep')}>/</div>
              <CNavLink href='/contact-us' className={cx('title', `${path === '/contact-us' ? 'active' : ''}`)}>
                Contact
              </CNavLink>
              <div className={cx('sep')}>/</div>
              <CNavLink href='/policy' className={cx('title', `${path === '/policy' ? 'active' : ''}`)}>
                Policy
              </CNavLink>
            </CNavbarNav>
          </CCollapse>
        </CContainer>
      </CNavbar>
    </header>
  );
};

export default Header;
